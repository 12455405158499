import React from "react";
import Container from "../components/Container";
import Fade from "../components/Fade.jsx";
import PageTitle from "../components/PageTitle.jsx";
import PriceListItem from "../components/PriceListItem";
import Subtitle from "../components/Subtitle.jsx";
import Title from "../components/Title";

function Pricing() {
  return (
    <>
      <PageTitle>Hinnasto</PageTitle>
      <Container>
        {" "}
        <div className="text-center col">
          <Fade>
            <Title>Hinnasto</Title>
            <Subtitle>1.9.2024 alkaen (sis. alv 25.5%)</Subtitle>
          </Fade>
        </div>
        <div className="grid grid-cols-1 mt-20 md:grid-cols-2 auto-cols-max md:gap-20">
          <div>
            <Fade bottom>
              <Title sm>Volyymiripsienpidennykset</Title>
            </Fade>
            <Fade>
              <PriceListItem
                product="Uudet 2-2,5h"
                price="130"
                className="mb-10"
              />

              <h4 className="mt-3 mb-4 text-2xl font-medium font-playfair">
                Huollot:
              </h4>
              <PriceListItem
                product="2 viikkoa edellisestä huollosta"
                price="73"
              />
              <PriceListItem
                product="3 viikkoa edellisestä huollosta"
                price="78"
              />
              <PriceListItem
                product="4 viikkoa edellisestä huollosta"
                price="85"
              />
              <PriceListItem product="Megamix huolto" price="90" />
              <PriceListItem
                product="Huolto muualta tulevat asiakkaat"
                price="89"
              />
              <PriceListItem
                product="Pikahuolto 45min. (vain kanta-asiakkaat)"
                price="53"
              />
              <PriceListItem
                product="Ripsienpidennysten poisto, 30 min."
                price="33"
                className="mb-10"
              />
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <Title sm>Klassiset ripsienpidennykset</Title>
            </Fade>
            <Fade>
              <PriceListItem
                product="Uudet 1,5-2h"
                price="104"
                className="mb-10"
              />

              <h4 className="mt-3 mb-4 text-2xl font-medium font-playfair">
                Huollot:
              </h4>
              <PriceListItem
                product="3 viikkoa edellisestä huollosta"
                price="71"
              />
              <PriceListItem
                product="4 viikkoa edellisestä huollosta"
                price="75"
              />
              <PriceListItem
                product="Huolto muualta tulevat asiakkaat"
                price="75"
              />
              <PriceListItem
                product="Pikahuolto 45min. (vain kanta-asiakkaat)"
                price="53"
              />
              <PriceListItem
                product="Ripsienpidennysten poisto, 30 min."
                price="33"
              />
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <Title sm>Lash Lift - ripsien kestotaivutus</Title>
            </Fade>
            <Fade>
              <PriceListItem
                product="Lash Lift ripsien värjäyksellä"
                price="90"
              />
              <PriceListItem product="Lash Lift ilman värjäystä" price="82" />
              <PriceListItem product="Lash Lift Reverse/suoristus" price="41" />
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <Title sm>Jalkahoidot</Title>
            </Fade>
            <Fade>
              <PriceListItem product="Klassinen jalkahoito" price="77" />
              <PriceListItem
                product="Klassinen jalkahoito lakkauksella"
                price="87"
              />
              <PriceListItem product="Spa-jalkahoito" price="93" />
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <Title sm>Shellac geelilakkaukset</Title>
            </Fade>
            <Fade>
              <PriceListItem product="Käsien geelilakkaus uudet" price="55" />
              <PriceListItem product="Geelilakkaus huolto" price="60" />
              <PriceListItem product="Geelilakkauksen poisto" price="15" />
              <PriceListItem
                product="Varpaiden geelilakkaus uudet"
                price="60"
              />
              <PriceListItem
                product="Varpaiden geelilakkaus huolto"
                price="68"
              />
              <PriceListItem
                product="Varpaiden geelilakkauksen poisto"
                price="15"
              />
              <PriceListItem
                product="Geelilakkaus kädet + varpaat"
                price="70"
              />
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <Title sm>Kasvohoidot</Title>
            </Fade>
            <Fade>
              <PriceListItem
                product="Emblica C - kirkastava C-vitamiinihoito"
                price="83"
              />
              <PriceListItem
                product="First of all - kosteuttava kasvohoito"
                price="93"
              />
              <PriceListItem
                product="Propical - puhdistava kasvohoito"
                price="88"
              />
              <PriceListItem product="Ultraäänipuhdistus" price="83" />
              <PriceListItem product="Timanttihionta" price="83" />
              <PriceListItem
                product="Quick Peel - hedelmähappohoito"
                price="55"
              />
              <PriceListItem product="Luxus- kasvohoito" price="100" />
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <Title sm>Spa-hoidot</Title>
            </Fade>
            <Fade>
              <PriceListItem product="Kokovartalohieronta" price="77" />
              <PriceListItem product="Kuumakivihieronta 45min" price="62" />
              <PriceListItem product="Kuumakivihieronta 60min" price="77" />
              <PriceListItem product="Kasvolymfa" price="70" />
              <PriceListItem
                product="Intialainen päähieronta 30min"
                price="40"
              />
              <PriceListItem
                product="Intialainen päähieronta 45min"
                price="55"
              />
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <Title sm>Sokeroinnit</Title>
            </Fade>
            <Fade>
              <PriceListItem product="Säärien sokerointi" price="44" />
              <PriceListItem product="Reisien sokerointi" price="44" />
              <PriceListItem product="Kainaloiden sokerointi" price="34" />
              <PriceListItem product="Käsivarsien sokerointi" price="34" />
              <PriceListItem product="Bikinirajan sokerointi" price="34" />
              <PriceListItem
                product="Sokerointipaketti: Bikiniraja + kainalot + sääret"
                price="97"
              />
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <Title sm>Ripset ja Kulmat</Title>
            </Fade>
            <Fade>
              <PriceListItem product="Kulmien värjäys" price="23" />
              <PriceListItem product="Kulmien laminointi" price="56" />
              <PriceListItem product="Ripsien värjäys" price="23" />
              <PriceListItem product="Kulmien värjäys ja muotoilu" price="32" />
              <PriceListItem
                product="Ripsien ja kulmien värjäys sekä kulmien muotoilu"
                price="37"
              />
            </Fade>
          </div>
        </div>
      </Container>
      <div className="bg-white">
        <Container>
          <div className="max-w-5xl mx-auto text-center">
            <Fade bottom className="text-center">
              <Title centered sm>
                Ajanvarauksen peruutusehdot
              </Title>
            </Fade>
            <Fade>
              <p>
                Varaamalla ajan sitoudut näihin peruutusehtoihin.
                <br />
                Voit peruuttaa aikasi veloituksetta verkossa 48h ennen varattua
                aikaasi. Tämän jälkeen peruutukset vain sähköpostilla{" "}
                <a href="mailto:info@adelianbeauty.fi">info@adelianbeauty.fi</a>
                . Alle 48h peruutetuista ajoista veloitamme 50% palvelun
                hinnasta. Kokonaan peruuttamattomista ajoista veloitamme 100%
                varatun palvelun hinnasta sekä laskutuslisä 5€.
                Sairastapauksissa aikaa on mahdollista siirtää yhden kerran
                veloituksetta. Jos tämä aika peruuntuu on yrityksellä oikeus
                periä 100% palvelun hinnasta sekä laskutuslisä 5€.
              </p>
            </Fade>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Pricing;
