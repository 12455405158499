import React from "react";
import { Link } from "react-router-dom";
import ContactInformation from "../components/ContactInformation";
import Container from "../components/Container";
import Title from "../components/Title";

function Footer() {
  return (
    <footer className="text-black">
      <div className="container p-12 mx-auto">
        <div className="grid w-full grid-cols-1 md:grid-cols-3">
          <div className="mb-5">
            <div className="grid text-left">
              <h2 className="mb-3 text-3xl font-playfair">Navigoi</h2>
              <div>
                <Link to="/" className="w-auto link link-hover">
                  Etusivu
                </Link>
              </div>
              <div>
                <Link to="/booking" className="link link-hover">
                  Ajanvaraus
                </Link>
              </div>
              <div>
                <Link to="/trainings" className="link link-hover">
                  Koulutukset
                </Link>
              </div>
              <div>
                <Link to="/pricing" className="link link-hover">
                  Hinnasto
                </Link>
              </div>
              <div>
                <Link to="/about" className="link link-hover">
                  Henkilökunta
                </Link>
              </div>
              <div>
                <Link to="/contact" className="link link-hover">
                  Yhteystiedot
                </Link>
              </div>
            </div>
          </div>
          <div className="mb-5">
            <h2 className="mb-3 text-3xl font-playfair">Sosiaalinen media</h2>
            <div>
              <p>
                <a
                  href="https://www.facebook.com/adelianbeauty"
                  className="link link-hover"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </p>
              <p>
                <a
                  href="https://www.instagram.com/adelianbeauty/"
                  className="link link-hover"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </p>
            </div>
          </div>
          <div>
            <h2 className="mb-3 text-3xl font-playfair">Yhteystiedot</h2>
            <ContactInformation />
          </div>
        </div>
      </div>
      <div className="w-full p-5 mt-5 text-center border-t-1 border-t-gray-200">
        Copyright © Adelia'n Beauty {new Date().getFullYear()}
      </div>
    </footer>
  );
}

export default Footer;
